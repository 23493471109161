import * as React from 'react';
import logo from 'img/gobet-logo-sa.png';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Input, message } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getOtpData } from 'utils/getAuthTokens';
import Storage from 'constants/storage';
import { ApiErrorCode, getAPIErrorCode } from 'utils/api';
import { globalTranslate } from 'utils/globalMessages';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAccount } from 'store/accountState';
import { useHistory } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import BackgroundImage from './assets/login-bg.svg';
import { Account, AccountRole } from '../../../types/graphqlTypes';

interface IInputWithIcon {
  placeholder: string;
  icon: string;
  [key: string]: any;
}

const InputWithIcon = ({ placeholder, icon, ...props }: IInputWithIcon) => (
  <Input
    {...props}
    size="large"
    className="mt-2"
    placeholder={placeholder}
    prefix={<LegacyIcon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />}
  />
);

const InputWithIconPassword = ({
  placeholder,
  icon,
  ...props
}: IInputWithIcon) => (
  <Input.Password
    {...props}
    size="large"
    className="mt-2"
    placeholder={placeholder}
    prefix={<LegacyIcon type={icon} style={{ color: 'rgba(0,0,0,.25)' }} />}
  />
);

const ME = gql`
  query Me {
    me {
      id
      role
    }
  }
`;

const StyledButton = styled.button`
  display: block;
  width: 100%;
  height: 40px;
  background: #1890ff;
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  transition: 0.4s ease;

  :hover {
    opacity: 0.7;
  }
`;

export default () => {
  const intl = useIntl();
  const history = useHistory();
  const { setPortal } = useAccount();
  const [loading, setLoading] = React.useState(true);
  const client = useApolloClient();

  React.useEffect(() => {
    setPortal('sa');
    if (
      !localStorage.getItem(Storage.SA_ACCESS_TOKEN) ||
      !localStorage.getItem(Storage.SA_REFRESH_TOKEN)
    ) {
      localStorage.removeItem(Storage.ACCESS_TOKEN);
    } else {
      localStorage.setItem(
        Storage.ACCESS_TOKEN,
        localStorage.getItem(Storage.SA_ACCESS_TOKEN) || ''
      );
      history.push('/controlcenter');
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (data: any) => {
    const { account, password } = data;

    try {
      const response = await getOtpData(account.trim(), password);

      const {
        data: { access, refresh },
      } = response;

      setPortal('sa');
      localStorage.setItem(Storage.SA_ACCESS_TOKEN, access);
      localStorage.setItem(Storage.SA_REFRESH_TOKEN, refresh);

      localStorage.setItem(Storage.ACCESS_TOKEN, access);

      const dataMe = await client.query({
        query: ME,
        fetchPolicy: 'network-only',
      });
      const me: Account = dataMe?.data?.me;
      if (me?.role !== AccountRole.SuperAdmin) {
        message.error(
          'You entered an invalid superadmin credentials. Kindly contact customer service for further details'
        );
        localStorage.removeItem(Storage.SA_ACCESS_TOKEN);
        localStorage.removeItem(Storage.SA_REFRESH_TOKEN);
        localStorage.removeItem(Storage.ACCESS_TOKEN);
        return;
      }

      history.push('/controlcenter');
    } catch (error) {
      const code = getAPIErrorCode(error);

      message.error(
        globalTranslate(code || ApiErrorCode.INTERNAL_SERVER_ERROR, intl)
      );
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div
      style={{
        height: '100vh',
        width: 'auto',
        backgroundImage: `url(${BackgroundImage})`,
      }}
      className="d-flex justify-content-center align-items-center text-center"
    >
      <div style={{ width: '25%' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center justify-content-center">
            <img src={logo} className="mr-2" alt="logo" />
          </div>
          <p className="text-muted mt-2">SuperAdmin Login Portal</p>
          <p className="mt-2">Account Login</p>

          <div className="mt-3">
            <Controller
              as={InputWithIcon}
              control={control}
              name="account"
              defaultValue=""
              placeholder="Account"
              icon="user"
              rules={{ required: true, minLength: 3 }}
            />
            {errors.account?.type === 'required' && (
              <span style={{ color: 'red' }}>Account name is required.</span>
            )}
            {errors.account?.type === 'minLength' && (
              <span style={{ color: 'red' }}>
                Must be at least 3 characters.
              </span>
            )}

            <Controller
              as={InputWithIconPassword}
              control={control}
              name="password"
              defaultValue=""
              placeholder="Password"
              icon="lock"
              rules={{ required: true }}
            />
            {errors.password && (
              <span style={{ color: 'red' }}>Password is required.</span>
            )}

            <div className="d-flex justify-content-space-between">
              <InputWithIcon
                style={{ width: '80%' }}
                icon="mail"
                placeholder="Verification Code"
              />

              <Button
                className="mt-2"
                size="large"
                style={{
                  width: '19%',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  fontSize: '11px',
                }}
              >
                Verification Code
              </Button>
            </div>
          </div>

          <div className="mt-2">
            <StyledButton type="submit">Login</StyledButton>
          </div>
        </form>
      </div>
    </div>
  );
};
